import NavBar from "./components/nav-bar/nav-bar";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home-page/home-page";
import SamponiPage from "./pages/samponi-page/samponi-page";
import StamparijaPage from './pages/stamparija-page/stamparija-page';
import CetkicePage from "./pages/cetkice-page/cetkice-page";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="App">
      <NavBar></NavBar>
      <div>
        <Routes>
          <Route path="/" element={<HomePage/>}></Route>
          <Route path="/stamparija" element={<StamparijaPage/>}></Route>
          <Route path="/samponi" element={<SamponiPage/>}></Route>
          <Route path="/cetkice" element={<CetkicePage/>}></Route>
        </Routes>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
