import './cetkice-page.css';
import glassImage from '../../images/glassImage.png';
import biorazgradivaCetkica from '../../images/biorazgradiva-cetkica.png';
import plasticnaCetkica from '../../images/plasticna-cetkica.png';
import kutijaImage from '../../images/kutija.png';
import { useLayoutEffect } from 'react';

const CetkicePage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0,0);
    });

    return (
        <div className="cetkicePageContent">
            <div className="cetkiceSection">
                <div className="glassImage">
                    <img src={glassImage} alt="cetkica na casti" />
                </div>
                <div className="cetkiceContent">
                    <p className="heading">Jednokratne četkice sa intergisanom pastom za zube</p>
                    <div className="cetkiceImages">
                        <div className="cetkicaImage">
                            <img src={biorazgradivaCetkica} alt="biorazgradiva cetkica" />
                            <p>Sa biorazgradivom drškom</p>
                        </div>
                        <div className="cetkicaImage">
                            <img src={plasticnaCetkica} alt="plasticna cetkica" />
                            <p>Sa plastičnom drškom</p>
                        </div>
                    </div>
                    <p>Četkice su izradjene od biorazgradivog materijala i medicinskog silikona jako prijatne za korišćenje u svakoj prilici.</p>
                </div>
            </div>
            <div className="kutijaSection">
                <div className="kutijaImage">
                    <img src={kutijaImage} alt="kutija za cetkice" />
                    <p>Kutija se štampa i brendira po vašem zahtevu</p>
                </div>
                <p>Takodje, uz same četkice nudimo i kutije koje su izradjene od kvalitetnog kartona. Upadljive, modernog dizajna koji privlači pažnju i osigurava da vaša reklama ne ostane neprimećena.</p>
            </div>
        </div>
    )
}

export default CetkicePage;