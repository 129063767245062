import './stamparija-page.css';
import { useLayoutEffect } from 'react';
import masina1 from '../../images/dinex-masina1.png';
import masina2 from '../../images/dinex-masina2.png';
import masina3 from '../../images/dinex-masina3.png';
import rad1 from '../../images/rad1.png';
import rad2 from '../../images/rad2.png';
import rad3 from '../../images/rad3.png';
import rad4 from '../../images/rad4.png';
import rad5 from '../../images/rad5.png';
import rad6 from '../../images/rad6.jpg';
import rad7 from '../../images/rad7.jpg';
import rad8 from '../../images/rad8.jpg';
import rad9 from '../../images/rad9.jpg';
import rad10 from '../../images/rad10.jpg';

const StamparijaPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0,0);
    });

    return (
        <>
            <section className="usluge">
                <div className="uslugeKojeNudimo">
                    <p>Štamparija Dinex doo nudi sledeće usluge:</p>
                    <ul>
                        <li>Priprema za štampu</li>
                        <li>Digitalna štampa</li>
                        <li>Offset štampa</li>
                        <li>Sečenje</li>
                        <li>Koričenje</li>
                        <li>Komplet knjigovezačku doradu</li>
                        <li>Isporuku do kupca</li>
                    </ul>
                </div>
                <div className="uslugeSlika">
                    <img src={masina1} alt="dinex-masina" loading='lazy'/>
                </div>
                <div className="uslugeSlika">
                    <img src={masina2} alt="dinex-masina" loading='lazy'/>
                </div>
            </section>
            <section className="radovi">
                <p>Primeri nekih od naših dosadašnjih radova</p>
                <div className="ranijeUslugeBoxes">
                    <div className="uslugeBox">
                        <img src={rad1} alt="rad1" loading='lazy'/>
                    </div>
                    <div className="uslugeBox">
                        <img src={rad2} alt="rad2" loading='lazy'/>
                    </div>
                    <div className="uslugeBox">
                        <img src={rad3} alt="rad3" loading='lazy'/>
                    </div>
                    <div className="uslugeBox">
                        <img src={rad4} alt="rad4" loading='lazy'/>
                    </div>
                    <div className="uslugeBox">
                        <img src={rad5} alt="rad5" loading='lazy'/>
                    </div>
                </div>
                <div className="ranijeUslugeBoxes">
                    <div className="uslugeBox">
                        <img src={rad6} alt="rad6" loading='lazy'/>
                    </div>
                    <div className="uslugeBox">
                        <img src={rad7} alt="rad7" loading='lazy'/>
                    </div>
                    <div className="uslugeBox">
                        <img src={rad8} alt="rad8" loading='lazy'/>
                    </div>
                    <div className="uslugeBox">
                        <img src={rad9} alt="rad9" loading='lazy'/>
                    </div>
                    <div className="uslugeBox">
                        <img src={rad10} alt="rad10" loading='lazy'/>
                    </div>
                </div>
            </section>
            <section className="staStampamo">
                <div className="stampamoList">
                    <p>Za Vas smo u mogućnosti da štampamo:</p>
                    <div className="lists">
                        <ul>
                            <li>Brošure</li>
                            <li>Flajere</li>
                            <li>Prospekte</li>
                            <li>Kateloge</li>
                            <li>Sveske</li>
                            <li>Postere</li>
                            <li>Plakate</li>
                            <li>Jelovnike i menije</li>
                            <li>Memorandume</li>
                            <li>Ulaznice</li>
                            <li>Obrasce</li>
                        </ul>
                        <ul>
                            <li>Vizit karte</li>
                            <li>Koverte</li>
                            <li>Deklaraceije i etikete</li>
                            <li>Liste za kladionice</li>
                            <li>Fascikle</li>
                            <li>Klendare</li>
                            <li>Diplome i sertifikate</li>
                            <li>NRC blokove</li>
                            <li>Vaučere</li>
                        </ul>
                        <ul>
                            <li>Roll up banere</li>
                            <li>Reklamne papirne kese</li>
                            <li>Časopise kao i knjige, svih formata</li>
                        </ul>
                    </div>
                </div>
                <div className="stampamoImg">
                    <img src={masina3} alt="dinex-masina3" loading='lazy'/>
                </div>
            </section>
        </>
    )
}

export default StamparijaPage;