import './samponi-page.css';
import image1 from '../../images/AlfaDOG_1920x1080px-1.jpg';
import image2 from '../../images/AlfaDOG_1920x1080px-2.jpg';
import image3 from '../../images/AlfaDOG_1920x1080px-3.jpg';
import image4 from '../../images/AlfaDOG_1920x1080px-4.jpg';
import image5 from '../../images/AlfaDOG_1920x1080px-5.jpg';
import { useEffect, useLayoutEffect, useState } from 'react';
import Modal from '../../components/modal/modal';

const SamponiPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0,0);
    });

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (openModal) {
            document.body.style.overflow = 'hidden';
        } 
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, [openModal])

    return (
        <div className="samponiPageContent">
            <Modal open={openModal} onClose={() => setOpenModal(false)}/>
            <div className="samponiDescription">
                <h3>Specijalna ponuda</h3>
                <p>Dinex doo uvek osluškuje potrebe tržišta i svoje poslovanje usmerava u tom pravcu. U prilici smo da Vam predstavimo paletu proizvoda za negu kućnih ljubimaca - šamponi za pse.</p>
                <p>Aktivna formula temeljno čisti od korena do vrha dlake, štiteći je od spoljašnjih uticaja. Pomaže u sprečavanju dehidratacije kože nakon kupanja. Optimalan pH i inovativan sadržaj formule, vraća mikrobioti kože i prirodni balans. Olakšava isčešljavanje i čini dlaku mekom, zdravom i sjajnom. Bez fosfata, glutena i parabena. 100% prirodan.</p>
            </div>
            <div className="row">
                <img src={image1} alt="slika1" loading='lazy' onClick={setOpenModal}/>
                <img src={image2} alt="slika2" loading='lazy' onClick={setOpenModal}/>
            </div>
            <div className="row">
                <img src={image3} alt="slika3" loading='lazy' onClick={setOpenModal}/>
                <img src={image4} alt="slika4" loading='lazy' onClick={setOpenModal}/>
            </div>
            <img src={image5} alt="slika5" loading='lazy' onClick={setOpenModal}/>
        </div>
    )
}

export default SamponiPage;