import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footerDesc">
                <p>Dinex D.O.O, Kneževacka 17-19, Rakovica, Beograd</p>
                <p><b>MAT. BR.</b> 07449364 <b>PIB</b> 100570234</p>
            </div>
            <div className="footerKontakt">
                <p>Kontaktirajte nas putem telefona ili emaila</p>
                <div className="kontaktSection">
                    <div className="phones">
                        <a href="tel:+38163235663">+38163235663 - Direkcija</a>
                        <a href="tel:+381631860860">+381631860860 - Direkcija</a>
                        <a href="tel:+381695552907">+381695552907 - Prodaja</a>
                    </div>
                    <div className="emails">
                        <a href="mailto:dinex@mts.rs">dinex@mts.rs</a>
                        <a href="mailto:direkcija@dinexexportimport.rs">direkcija@dinexexportimport.rs</a>
                        <a href="mailto:prodaja@dinexexportimport.rs">prodaja@dinexportimport.rs</a>
                    </div>
                </div>
            </div>
            <div className="footerMenu">
                <Link to={"/"}>HOME</Link>
                <p>|</p>
                <Link to={"/stamparija"}>ŠTAMPARIJA</Link>
                <p>|</p>
                <Link to={"/samponi"}>ŠAMPONI</Link>
                <p>|</p>
                <Link to={"/cetkice"}>ČETKICE</Link>
            </div>
        </div>
    )
}

export default Footer;