import './nav-bar.css'
import LogoImg from '../../images/dinex-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
    const [isActive, setIsActive] = useState(false);

    const openMobileMenu = event => {
        setIsActive(current => !current);
    }

    function handleScroll() {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <header>
            <div className="navbar">
                <div className="logo">
                    <Link to={"/"}>
                        <img src={LogoImg} alt='dinex-logo'/>
                    </Link>
                </div>
                <ul className="nav-links">
                    <li><Link to={"/"}>Home</Link></li>
                    <li><Link to={"/stamparija"}>Štamparija</Link></li>
                    <li><Link to={"/samponi"}>Šamponi</Link></li>
                    <li><Link to={"/cetkice"}>Četkice</Link></li>
                </ul>
                <Link className='actionButton' onClick={handleScroll}>
                    Kontaktirajte nas
                </Link>
                <div className="menuToggleButton" onClick={openMobileMenu}>
                    <FontAwesomeIcon icon={faBars} style={{color: "#ffffff"}} size='xl' />
                </div>
            </div>

            <div className={`dropdownMenu ${isActive ? 'open' : ''}`}>
                <li><Link to={"/"} onClick={openMobileMenu}>Home</Link></li>
                <li><Link to={"/stamparija"} onClick={openMobileMenu}>Štamparija</Link></li>
                <li><Link to={"/samponi"} onClick={openMobileMenu}>Šamponi</Link></li>
                <li><Link to={"/cetkice"} onClick={openMobileMenu}>Četkice</Link></li>
                <li><Link className='actionButton' onClick={handleScroll}>
                    Kontaktirajte nas
                </Link></li>
            </div>
        </header>
    );
}

export default NavBar;