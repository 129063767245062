import React from "react";
import '../modal/modal.css';

const Modal = ({open, onClose}) => {

    if (!open) return null;
    return (
        <div onClick={onClose} className="overlay">
            <div onClick={(e) => {
                e.stopPropagation();
            }} className="modalContainer">
                <div className="modalClose" onClick={onClose}>X</div>
                <div className="modalContent">
                    <h2>OVDE MOZETE PORUCITI</h2>
                    <p>na komad ili na veliko ukoliko ste pravno lice</p>
                    <p className="pravnoLice">Posebne pogodnosti za pravna lica</p>
                </div>
                <div className="modalKontakt">
                    <p>Kontaktirajte nas na:</p>
                    <div className="telEmail">
                        <div className="tel">
                            <a href="tel:+38163235663">+38163235663</a>
                            <a href="tel:+381631860860">+381631860860</a>
                            <a href="tel:+381695552907">+381695552907</a>
                        </div>
                        <div className="email">
                            <a href="mailto:dinex@mts.rs">dinex@mts.rs</a>
                            <a href="mailto:prodaja@dinexexportimport.rs">prodaja@dinexportimport.rs</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;