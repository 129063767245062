import './home-page.css';
import { Link } from 'react-router-dom';
import { useLayoutEffect } from 'react';

const HomePage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0,0);
    });

    return (
        <div className="homePageContent">
            <div className="aboutCompany">
                <h1>Dinex <b>D.O.O.</b></h1>
                <p className="desc">štamparija uspešno radi preko 30 godina i iza sebe ima veliki broj zadovoljnih klijenata. Naše iskustvo i zadovoljni klijenti su garant kvaliteta usluga koje pružamo.</p>
                <p className="contact">Za vise informacija o nasim proizvodima i uslugama kontaktirajte nas na:</p>
                <a href='tel:+38163235663'>+38163235663</a>
                <a href='tel:+381631860860'>+381631860860</a>
                <a href='tel:+381695552907'>+381695552907</a>
            </div>
            <div className="boxesContent">
                <div className="boxImages">
                    <Link to={"/stamparija"}>
                        <div className="boxWithImage">
                            <div className="dark">
                                <p>STAMPARIJA</p>
                            </div>
                        </div>
                    </Link>
                    <Link to={"/samponi"}>
                        <div className="boxWithImage">
                            <div className="dark">
                                <p>SAMPONI</p>
                            </div>
                        </div>
                    </Link>
                    <Link to={"/cetkice"}>
                        <div className="boxWithImage">
                            <div className="dark">
                                <p>CETKICE</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <p className="desc1">Specijalna ponuda - u prilici smo da vam predstavimo paletu proizvoda za negu kućnih ljubimaca - šamponi za pse.</p>
                <p className="desc2">Posedujemo najkvalitetnije Xerox mašine za digitalnu štampu koje daju visok kvalitet otiska. Za offset štampu koristimo najbolje Heidelberg mašine.</p>
            </div>
        </div>
    )
}

export default HomePage;